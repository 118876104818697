/* GENERAL */
body { }

a,
a:visited {
	color: $linkColor;
    text-decoration: none;
}
a:hover {
	color: $linkColor;
    text-decoration: underline;
}

::-moz-selection {
    background: $selectionColor;
    text-shadow: none;
}

::selection {
    background: $selectionColor;
    text-shadow: none;
}

/* HEADINGS / TYPOGRAPHY */
h1,
h2,
h3,
h4 {
	font-weight: normal;
	margin: 0 0 10px 0;
}
h1 { }
h2 { }
h3 { }
h4 { }

/* STRUCTURE */
#container {
	max-width: $pageWidth;
	margin: 20px auto;
}

/* HEADER */
header { }
#logo { }

/* NAV */
nav { }

/* MAIN */
#main { }

/* SIDEBAR */
aside { }

/* FOOTER */
footer { }

/****************************************
PAGE TEMPLATES
*****************************************/

/* Home Page */
.home { }

/* Blog Page */
.blog { }
