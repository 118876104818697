/*
Theme Name: Drupal 7 Gulp Starter Theme
Theme URI: https://github.com/lasseyls/Drupal-7-Starter-Theme
Description: Drupal 7 Gulp Starter Theme for use as a starting template for building custom themes.
Author: Lasse Moos
Author URI: http://www.yourlocalstudio.dk
License: GNU General Public License v2.0
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Version: 1.0
*/

// Reset, Variables, Mixins, Etc
// DO NOT EDIT ORDER OF FILES
@import "partials/mixins";
@import "partials/variables";
@import "partials/normalize";

// Globals
@import "partials/global";

// The grid
@import "partials/grid";

// Page and Site Styles
@import "partials/page";
@import "partials/posts";
@import "partials/blocks";
@import "partials/search";
@import "partials/forms";

// Helper Classes, Print, Etc
@import "partials/helpers";
@import "partials/print";

//jeet
@import 'jeet/jeet';
@import 'compass-breakpoint/breakpoint';

//fonts
@import url(https://fonts.googleapis.com/css?family=Libre+Baskerville:400,400italic,700|Josefin+Sans:400,400italic,300,600|Josefin+Slab:400,600,700,300);


$max: 1080px;
$footerheight:200px;

$medium:500px;

$tablet:700px;
$desktop:1000px;

$transition:all 0.4s ease;
$boxshadow: inset 0px 0px 4px $shadow;


// @include j-edit();

.top-section-wrapper {
    background: $teal;
    @include breakpoint($desktop) {
    position: fixed;
    top:0;
    left:0;
    right:0;
    z-index:9999;
    box-shadow: 0px 2px 4px rgba($text, 0.25), inset 2px 2px 4px rgba($text, 0.25);
}
}

#top-section {
    @include   j-center($max);
    padding:0 20px;

}
#slider-section {
    position: relative;
    @include j-center(1300px);
}


#main {
    @include j-center($max);
    padding-left: 20px;
    padding-right: 20px;
}

html {
    font-family: $libre;
    color:$text;
    font-size: 15px;
    background:url("../images/blizzard.png") repeat;
    line-height: 1.6em;
}

h1, h2, h3, h4 {
    font-family: $slab;
    font-weight: 600;
    line-height: 1em;

}

h1 {
    font-size: 2.4em;
    color:$teal;
    margin: 0;
    @include breakpoint($medium) {
        &:before {
            content:"≈ ";
            position: relative;
        }
        padding-left: 40px;
        text-indent: -40px;
    }
}

h2 {
    color:$text;
    font-size: 2em;
    margin: 0;
    // @include breakpoint($medium) {
    //     &:before {
    //         content:"≈ ";
    //     }
    // }
}


figure {
    margin: 0;
}

figure { display: table; }


figcaption {
    caption-side: bottom ;
    display: table-caption;
     }

#title {
    position: absolute;
    top:1em;
    left:2em;
    z-index:99;
    font-size: 2em;
    h2 {
        font-size: 1em;
        line-height: 1em;
    }
}
h2.sitename, h2.siteslogan {
    color:white;
    line-height: 1.6em;
    font-size: 2.4em;
    text-shadow:0px 0px 2px $text;
}

h2.siteslogan {
    // &:before {
    //     content:"";
    // }
    &:after {
        content:" ≈";
    }
}

h2.sitename {
    &:before {
        content:"≈ ";
    }
    // &:after {
    //     content:" ≈";
    // }
}

h3 {
    color:$teal;
    font-size: 2em;
}

aside h3, h3.pane-title {
    clear:both;
    margin-top: 20px;
//     @include breakpoint($tablet) {
//     margin-top: 60px;
// }
}


img:not(.leaflet-image-layer):not(.leaflet-marker-icon) {
    max-width:100%;
    height: auto !important;
}

.icon {
    padding-right: 10px;
}

.node {
    background:white;
    padding: 20px;
    box-shadow:$boxshadow;
}

// .page-node .node {
//     @include j-column(3/4);
// }

.node-aside {
    @include microclearfix;
    @include breakpoint($tablet) {
        @include j-column(1/4);
        margin-top: 40px;
        padding-left: 20px;
    }
}
.front .node-aside {
        @include breakpoint($tablet) {
        margin-top: 860px;
    }

}

.page-node #article{
    .field-body p:first-child {
        font-weight: 600;
    }

}

#main {
    // margin-top: -180px;
    z-index:99;
    position: relative;
}


.articledate {
    font-family: $sans;
    color:$text;
    // padding-left: 40px;
    a {
        color:white;
        text-decoration: underline;
    }
    &:before {
            content: "";

            /* use !important to prevent issues with browser extensions that change fonts */
            font-family: 'IcoMoon-Free' !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            font-size: 0.8em;

            /* Enable Ligatures ================ */
            letter-spacing: 0;
            -webkit-font-feature-settings: "liga";
            -moz-font-feature-settings: "liga=1";
            -moz-font-feature-settings: "liga";
            -ms-font-feature-settings: "liga" 1;
            -o-font-feature-settings: "liga";
            font-feature-settings: "liga";

            /* Better Font Rendering =========== */
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
    }
}

.article-list {
    padding: 40px 20px 20px;
    @include breakpoint($tablet) {
        padding: 20px;
    }
}

.front .view-berichten {
    @include breakpoint($tablet) {
    clear: both;
}
}

.article-list .articledate {
    // padding-left: 30px;
    color:$teal;
    a {
        color:$teal;
    }
}

.node-type-article {
    .articledate a {
        color:$text;
    }
}

ul.menu {
    margin: 0;
    padding: 0;
    font-family: $sans;
    @include microclearfix;
    li {
        list-style: none;
    }
    a {
        color:white;
        text-transform: uppercase;
        &.active {
            color:$text;
        }
    }
}

#main-menu ul ul {
    display:none;
}

#header ul.menu {
    margin: 0;
    padding: 0;
    line-height: 40px;
    height:40px;
    font-family: $sans;
    @include breakpoint($desktop) {
        font-size: 0.85em;
    }
    @include breakpoint(1200px) {
        font-size: inherit;
    }
    @include microclearfix;
    li {
        float:left;
        padding: 0 3px;
        &:before {
            content: "| ";
            color:white;
            padding-right: 3px;
        }
    }
    li:first-child:before {
        content:"";
    }
    li:first-child{
        padding-left: 0;
        margin-left: 0;
    }
}


    .caption, figcaption, .field-file-image-title-text {
        font-family: $sans;
        font-size: 0.8em;
        font-style: italic;
    }
    .field-file-image-title-text {
        display: block;
    }

    .media-element-container {
        padding: 0 20px 20px 0;
    }

    #social-links {
        font-family: $sans;
        margin: 0;
        padding: 0;
        position: absolute;
        right:0;
        top:0;
        z-index:99999;
        li {
            list-style:none;
            float:left;
            padding-right: 10px;
        }
        a {
            color:white;
            line-height: 52px;
            @include breakpoint($tablet) {
                line-height: 40px;
                transition:$transition;
            }

            &:hover{
                color:$text;
                text-decoration: none;
            }
        }
    }

    .service-links {
        font-family: $sans;
        margin: 0;
        padding: 0;
        position: absolute;
        top:10px;
        right:0;
        @include breakpoint($tablet) {
            position: inherit;
        }
        li {
            list-style:none;
            float:left;
            padding-right: 40px;
            &:last-child {
                padding-right: 20px;
            }
        }
        a {
            color:$teal;
            &:hover{
                color:$red;
                text-decoration: none;
            }
        }
    }

    .field-links a {
        font-family: $sans;
        padding-left: 25px;
        text-indent: -25px;
        display: block;


        &:before {
            content: "\e9cb";
            padding-right: 8px;

            /* use !important to prevent issues with browser extensions that change fonts */
            font-family: 'IcoMoon-Free' !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;

            /* Enable Ligatures ================ */
            letter-spacing: 0;
            -webkit-font-feature-settings: "liga";
            -moz-font-feature-settings: "liga=1";
            -moz-font-feature-settings: "liga";
            -ms-font-feature-settings: "liga" 1;
            -o-font-feature-settings: "liga";
            font-feature-settings: "liga";

            /* Better Font Rendering =========== */
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
    }

    article {
        position: relative;
    }
    .toplink {
        position: absolute;
        bottom:0;
        right:0;
        @include breakpoint($tablet) {
            right:-80px;
        }
    }

    .prev-next-link-prev, .prev-next-link-next {
        background:white;
        box-shadow:$boxshadow;
        padding: 20px;
        font-family: $sans;
        h3 {
            color:$text;
        }
        a {
            text-align: center;
            display: block;
            font-size: 1.4em;
        }
    }

    .prev-next-link-prev {
        @include j-column(2/4);
    }
    .prev-next-link-next {
        @include j-column(2/4);
        float:right;
    }
    .prev-next-date {
        color:$teal;
    }

    .node-readmore a {
        font-family: $sans;
        text-transform: lowercase;
        &:before {
            content:"> "
        }
    }

    .archive-header {
        background:$teal;
        padding: 10px;
        h2, .articledate {
            color:white;
        }
        .articledate {
            // padding-left: 30px;
        }
    }

    .mini-header {
        h2 {
            background:$teal;
            padding: 20px;
            font-size: 1.6em;
        }

        h2, .articledate {
            color:white;
        }
        .articledate {
            padding-left: 20px;
            color:$teal;
        }
    }


    .article-teaser,
    .article-miniteaser {
        padding: 0;
        .content {
            padding: 20px;
        }
    }

    .article-miniteaser {
        @include breakpoint($tablet) {
            @include j-column(1/3, $cycle:3);
        }
    }


    ul.facetapi-facetapi-links {
        margin: 0;
        padding: 0;
        li {
            list-style:none;
            margin: 0;
            padding: 0;
            font-family: $sans;

        }
        a {
            color:$text;
            &:before {
                content: "\ea53";
                padding-right: 8px;

                /* use !important to prevent issues with browser extensions that change fonts */
                font-family: 'IcoMoon-Free' !important;
                speak: none;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;

                /* Enable Ligatures ================ */
                letter-spacing: 0;
                -webkit-font-feature-settings: "liga";
                -moz-font-feature-settings: "liga=1";
                -moz-font-feature-settings: "liga";
                -ms-font-feature-settings: "liga" 1;
                -o-font-feature-settings: "liga";
                font-feature-settings: "liga";

                /* Better Font Rendering =========== */
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;

            }
            &.facetapi-active {
                visibility: hidden;
                color:$teal;
                &:before {
                    content: "\ea52";
                    visibility: visible;
                }
            }
        }
    }

    #sidebar {


        @include breakpoint($tablet) {
                    @include j-column(1/4);
                    margin-top: 140px;
        }
    }

    #sidebar, .node-aside {
        .field-image {
            margin-bottom: 20px;
            @include j-column(1/4, $cycle:4);
            @include breakpoint($tablet) {
                @include j-column(1, $cycle:1);
            }
        }
    }
    #article{
        @include breakpoint($tablet) {
          @include j-column(3/4);
      }
  }
  .article-node {
    @include breakpoint($tablet) {
      @include j-column(3/4);
  }
}

#footer {
    background:$teal;
}

html {
    height: 100%;
}

body {
    position: relative;
    min-height: 100%;
    @include breakpoint($desktop) {
    padding-top: 40px;
}
}

@include breakpoint($tablet) {
    #footer {
        position: absolute;
        bottom:0;
        left: 0;
        right: 0;
        height:$footerheight*2;
    }

    #main {
        padding-bottom: $footerheight*2+40;
    }
}

@include breakpoint($desktop) {
    #footer {
        height:$footerheight;
    }

    #main {
        padding-bottom: $footerheight+40;
    }
}

#header {
    padding-top: 52px;
    margin-bottom: -20px;
    @include breakpoint($medium){
    margin-bottom: -60px;
    }
    @include breakpoint($tablet){
    margin-bottom: -140px;
    }
    @include breakpoint($desktop) {
    padding-top: 0;
    }
    clear:both
}


.pane-pane-messages {
    @include breakpoint($tablet) {
        @include j-column(3/4);
    }
    background:white;
    z-index:101;
    position: relative;
    bottom:-2px;
    padding:10px;
}

.pane-page-breadcrumb {
    @include breakpoint($tablet) {
        @include j-column(3/4);
    }
    background:white;
    z-index:101;
    position: relative;
    bottom:-2px;
    padding:10px;
}

#webcams {
    @include j-cf();
}

#webcams .cam {
    @include breakpoint($tablet) {
        @include j-column(1/2, $cycle:2);
    }
    @include breakpoint($desktop) {
        @include j-column(1/3, $cycle:3);
    }
    margin-bottom: 20px;
    h2 {
        color:$teal;
        font-size: 1.2em;
    }
    img {
        width:100%;
    }
}



.map {
    @include breakpoint($tablet) {
        @include j-column(1/2);
    }
}

.leaflet-popup-content {
    font-family: $sans;
    font-size: 1.2em;
    margin: 8px 15px;
}

.leaflet-popup-content-wrapper {
    box-shadow:$boxshadow;
}

p {

  // /* These are technically the same, but use both */
  // overflow-wrap: break-word;
  // word-wrap: break-word;

  // -ms-word-break: break-all;
  // /* This is the dangerous one in WebKit, as it breaks things wherever */
  // word-break: break-all;
  // /* Instead use this non-standard one: */
  // word-break: break-word;

  // /* Adds a hyphen where the word breaks, if supported (No Blink) */
  // -ms-hyphens: auto;
  // -moz-hyphens: auto;
  // -webkit-hyphens: auto;
  // hyphens: auto;

}

.mean-container .mean-bar {
    font-family: $sans;
    z-index:9999;
    background:$teal;
    position: absolute;
    right:0;
    left:0;
}
.mean-container .mean-nav {
    background:$teal;
}

.pager {
    font-family: $sans;
}

.article-section {
    position: relative;
    @include j-cf();
    margin-top: 40px;
    @include breakpoint($tablet) {
        margin-top: 0;
    }
}



.bottom-sidebar {
    @include breakpoint($desktop){
        @include j-column(1/4);
        @include j-shift(3/4);
    }
    .pane-block {
        h3 {
            font-size: 1.2em;
        }
        @include j-column(1/3, $cycle:3);
    }
    @include breakpoint($desktop) {
        .pane-block {
            @include j-column(1);
            h3 {
                font-size: 2em
            }
        }
    }
}

.bottom-with-sidebar {
    @include breakpoint($desktop){
        @include j-column(3/4);
        @include j-shift(-1/4);
    }
}


.leaflet-container {
    background:none;
    border:1px solid $text;
}

h2 a, h2 a:visited {
    color:white;
}

// #map, #map2 {
//     height:400px;
//     @include breakpoint($tablet){
//         height:600px;
//     }
// }

.pane-page-content {
    clear:both;
}

.page-user .pane-page-content {
    clear:both;
    @include breakpoint($tablet) {
        @include j-column(3/4);
    }
}

.node-standard #article,
.page-user .pane-page-content,
.page-zoeken #article
{
    background:white;
    padding: 20px;
    box-shadow:$boxshadow;
}

input {
    max-width:100%;
}

.media-afbeelding_links {
    @include breakpoint($medium) {
        width:50%;
        height:auto;
        float:none;
    }
}

.media-afbeelding_tweederde {
    @include breakpoint($medium) {
        width:72%;
        height:auto;
        float:none;
    }
}

.sidebar  {
    h3 a, h3 a:visited {
        color:$teal;
    }
    ul.menu {
        margin: 0;
        padding: 0;
        a {
            color:$red;
        }
    }
}


/*
****************************
* =form
****************************
*/

.form-item.webform-component {
    clear:left;
}

.webform-component label,
#user-login label {
    clear:both;
    width:200px;
    float: left;
    font-weight: 500;
}

.webform-component .form-type-checkbox label {
    clear:none;
    width:auto;
    float:none;
    font-weight: 500;
}

.webform-component,
#user-login,
.form-item  {
    textarea, select,
    input[type="text"],
    input[type="password"],
    input[type="email"] {
        background: linear-gradient(to bottom, #FFFFFF 0%, #FAFAFA 100%) repeat scroll 0 0 rgba(0, 0, 0, 0);
        border: 1px solid #E8E8E8;
        border-radius: 4px;
        box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
        margin-bottom: 10px;
        padding: 8px 6px;
        width: 375px;
        float: left;
        &.error {
            border-color:$red;
        }
    }
}

.webform-component, #user-login, .form-item {
    input[type="text"]:focus,
    input[type="email"]:focus,
    input[type="password"]:focus,
    select:focus,
    textarea:focus {
        border: 2px solid $teal;
        padding: 7px 5px;
                &.error {
            border-color:$red;
        }
    }
}

.form-item .description {
    clear: both;
}
.form-actions.form-wrapper {
    clear: both;
}

.views-exposed-form .views-exposed-widget .form-submit {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'IcoMoon-Free' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 20px;

    /* Enable Ligatures ================ */
    letter-spacing: 0;
    -webkit-font-feature-settings: "liga";
    -moz-font-feature-settings: "liga=1";
    -moz-font-feature-settings: "liga";
    -ms-font-feature-settings: "liga" 1;
    -o-font-feature-settings: "liga";
    font-feature-settings: "liga";

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-top: 0;
    background: linear-gradient(to bottom, #FFFFFF 0%, #FAFAFA 100%) repeat scroll 0 0 rgba(0, 0, 0, 0);
    border: 1px solid #E8E8E8;
    border-radius: 4px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    padding: 8px 6px;
    float: left;
    color:$teal;
}

.view-zoeken{
    h3 {
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px dashed $teal;
    }
}


.sans {
    font-family: $sans;
}

/****************************
**  footer  **
****************************/

#footer {
    z-index:999;
    .menu {
        font-size: 0.85em;
        line-height: 1.4em;
    }
    h2 {
        font-size: 1.5em;
        color:white;
    }
}

.footer-wrapper {
    @include j-cf();
    @include j-center($max);
    padding:20px;
    @include breakpoint($max) {
        padding: 20px 0;
    }
}


.footer-tekst {
    margin-top: 40px;
    text-align: center;
}
@include breakpoint($tablet) {
    #footer {
        .pane-main-menu{
            @include j-span(1/2);
        }
        .pane-menu-footerlinks {
            @include j-span(1/2);
        }
        .pane-water-footer-tekst {
            @include j-span(1);
        }

    }
}

@include breakpoint($desktop) {
    #footer {
        .pane-main-menu{
            @include j-span(1/4);
        }
        .pane-menu-footerlinks {
            @include j-span(1/4);
        }

        .pane-water-footer-tekst {
            @include j-span(2/4);
        }
    }
}

.frontlinks {
    position: relative;
    z-index: 99;
    @include breakpoint($tablet) {
        position:absolute;
    }
}

.frontlinks a {

    font-family: $sans;
    display:block;
    width:100%;
    text-align: center;
    color:$red;
    transition: $transition;
    margin-bottom: 10px;
    &:hover {
        color:$text;
        text-decoration: none;

    }
}
.frontlinks i {
    display:block;
    font-size: 3em;
    width:100%;
    text-align: center;
}

/****************************
**  subsc  **
****************************/
#simple-subscription-form {
    .form-submit {
        margin-top: 0;
        background: linear-gradient(to bottom, #FFFFFF 0%, #FAFAFA 100%) repeat scroll 0 0 rgba(0, 0, 0, 0);
        border: 1px solid #E8E8E8;
        border-radius: 4px;
        box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
        margin-bottom: 10px;
        padding: 8px 6px;
        float: left;
        color:$teal;
        margin-left: 20px;
    }
    input {
        font-family: $sans;
    }
}
.pane-simple-subscription-subscribe,
.pane-simplenews-33 {
    @include breakpoint($tablet) {
        @include j-column(3/4);
    }
    padding: 20px;
    background-color: #FFF;
    box-shadow:$boxshadow;
    @include j-cf();
}

.pane-simplenews-33 {
    label {
    display: none;
}
        input {
        font-family: $sans;
    }
        .form-submit {
        margin-top: 0;
        background: linear-gradient(to bottom, #FFFFFF 0%, #FAFAFA 100%) repeat scroll 0 0 rgba(0, 0, 0, 0);
        border: 1px solid #E8E8E8;
        border-radius: 4px;
        box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
        margin-bottom: 10px;
        padding: 8px 6px;
        float: left;
        color:$teal;
        margin-left: 20px;
    }
}

.pane-simplenews-33 

.header-image {
    @include j-center(1300px);
    figcaption {
        @include j-column(1/4, $offset:3/4);
        float:none;
                display:none;
        @include breakpoint($tablet) {
            display: block;
        }
    }
}


body.navbar-fixed #navbar-administration.navbar-oriented,
#navbar-administration.navbar-oriented .navbar-bar,
#navbar-administration.navbar-oriented .navbar-tray {
    top: 40px;
}

.breadcrumb {
    font-size: 0.85em;
    font-family: $sans;
    padding-left: 40px;
    padding-bottom: 0;
}


#colorbox {
    font-family: $sans;
}

#cboxOverlay {
    background: $text;
    opacity: 0.65;
    filter: alpha(opacity = 65);
}

#cboxTitle {
    color: white;
    font-family: $sans;
}

@include breakpoint($tablet) {
#cboxTitle {
    font-size: 2em;
    top: -1em;
}

#cboxContent {
    margin-top: 50px;
}
};

.fotos-gebeurtenis {
    @include j-cf();
    margin-bottom: 40px;
    clear:both;
    .field-image {
        @include j-column(1/6, $cycle:6);
    }
}

#bottom {
    margin-bottom: 40px;
}

.pane-node-field-image {
    margin-top: 20px;
}

.field-body h2 {
    color: #367393;
    font-weight: 500;
    text-transform: uppercase;
    font-family: $sans;
    margin: 1.25em 0 0.25em;
}

.field-body h3 {
    color: #009999;
    font-size: 1em;
    padding: 0;
    /* text-transform: uppercase; */
    font-family: $libre;
    margin-top: 1.5em;
}
.field-body p {
    margin-top: 0;
    &:first-child {
        margin-top: 1em;
    }
}


.pane-node-syndicate {
    position: absolute;
    right:20px;
    bottom:20px;
}

.leaflet-control-fullscreen.leaflet-bar a, {
  background-position: inherit;
}

.leaflet-fullscreen-on .leaflet-control-fullscreen.leaflet-bar a, {
  background-position: 0 -26px;
}

/****************************
**  embed  **
****************************/

.page-embed-peilschalen #map,
.page-embed-kaart #map {
    height:calc(100vh);
    margin-bottom: 0;
}

/****************************
**  inhoudsopgave **
****************************/

#node-409 {
    margin-bottom:0;
}

.pane-inhoudsopogave {
    position: relative;
    ::after {
        content:"";
        height:5px;
        left:0;
        right:0;
        top:0;
        position: absolute;
        background: white;
    }
    padding: 20px;
    padding-top: 25px;
    position: relative;
    top: -5px;
    box-shadow:$boxshadow;
    clear: both;
    background: white;
        @include breakpoint($tablet) {
      @include j-column(3/4);
  }
}



.node .content h3 a {
    color:$teal;
    pointer-events: none;
    &:hover {
        color:$teal;
        text-decoration: none;
    }

}
